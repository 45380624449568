import Axios from "axios-observable";
import { Response } from "@libs/models/response";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { catchError, mergeMap, Observable, of, ReplaySubject, Subject, switchMap, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../storage/storage.service";
import { User } from "@libs/models/user";
import { MainService } from "@libs/main.service";
import { ProductInfo } from "@libs/models/product";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  user$: ReplaySubject<User | undefined> = new ReplaySubject<User | undefined>(1);
  user?: User;
  resetProductInfoSelected$ = new Subject<ProductInfo[]>();

  constructor(public router: Router, public storage: StorageService, public mainService: MainService) {
    this.listenUser();
  }

  listenUser() {
    this.user$.subscribe((user: User) => {
      this.user = user;
    });
  }

  get<T>(endpoint: string): Observable<Response<T>> {
    return this.makeRequest<T>("GET", endpoint);
  }

  post<T>(endpoint: string, data: any, isAuth = false): Observable<Response<T>> {
    const contentType = isAuth ? "urlencoded" : "json";
    return this.makeRequest<T>("POST", endpoint, data, contentType);
  }

  put<T>(endpoint: string, data: any): Observable<Response<T>> {
    return this.makeRequest<T>("PUT", endpoint, data);
  }

  delete<T>(endpoint: string, data?: any): Observable<Response<T>> {
    return this.makeRequest<T>("DELETE", endpoint, data);
  }

  resetUser() {
    this.user = undefined;
    this.user$.next(undefined);
  }

  private refresh(): Observable<{ status: boolean }> {
    const localRefreshToken = ""; //Implementar a lógica para pegar o refresh token do local storage
    const data = {
      refresh_token: localRefreshToken,
      grant_type: "refresh_token",
    };
    return this.post<any>("auth", data, true).pipe(
      mergeMap((response) => {
        const accessToken = response.data.accessToken;
        localStorage.setItem("accessToken", accessToken);
        return of({
          status: true,
        });
      }),
      catchError((error) => {
        console.error("Error refresh: ", error);
        return of({ status: false });
      })
    );
  }

  private makeRequest<T>(method: string, endpoint: string, data?: any, contentType: "json" | "urlencoded" = "json"): Observable<Response<T>> {
    const accessToken = localStorage.getItem("accessToken");
    const headers = {
      "Content-Type": contentType === "json" ? "application/json" : "application/x-www-form-urlencoded",
      Authorization: `Bearer ${accessToken && contentType === "json" ? accessToken : ""}`,
    };
    const config: AxiosRequestConfig = {
      method: method,
      url: `${this.mainService.endpointBase}/${endpoint}`,
      headers: headers,
      data: contentType === "json" ? data : new URLSearchParams(data).toString(),
    };
    return Axios.request<Response<T>>(config).pipe(
      switchMap((response: AxiosResponse<Response<T>>) => {
        return of({
          httpStatus: response.status,
          status: true,
          message: response.data.message || "",
          data: response.data.data as T,
        });
      }),
      catchError((error: AxiosError<Response<T>>) => {
        return this.handleErrorRequest<T>(error, () => this.makeRequest(method, endpoint, data, contentType));
      })
    );
  }

  private handleErrorRequest<T>(error: AxiosError<Response<T>>, originalRequest: () => Observable<Response<T>>): Observable<Response<T>> {
    const refreshToken = ""; //Implementar a lógica para pegar o refresh token do local storage
    if (error.response?.status === 401 && refreshToken) {
      return this.refresh().pipe(
        switchMap((refreshResponse) => {
          if (refreshResponse.status) {
            return originalRequest();
          } else {
            return of({
              httpStatus: error.response?.status,
              status: false,
              message: "Token refresh failed",
              data: error.response?.data,
            } as Response<T>);
          }
        })
      );
    } else {
      return of({
        httpStatus: error.response?.status,
        status: false,
        message: error.response?.data && error.response.data.message ? error.response.data.message : "",
        data: error.response?.data,
      } as Response<T>);
    }
  }
}
